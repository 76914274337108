<template>
  <div class="page customer-page" style="overflow: hidden">
    <Header />
    <HotlineMobileCard/>
    <div class="container">
        <div class="personal-card mb--sm--3 mb--md--0">
            <div class="personal-info mb--sm--10">
                <div class="personal-info__avata">
                    <div class="personal-info__status-dot"></div>
                    <Avatar/>
                </div>
                <div class="personal-info__content">
                    <div class="h5 font-weight--sm--500">{{$store.state.auth.user.name}}</div>
                    <div class="row">
                        <div class="col col--sm--6 col--md--4 font-weight--sm--500">{{$store.state.auth.user.partnr}}</div>
                        <div class="col col--sm--6 col--md--4 font-weight--sm--500">MST {{$store.state.auth.user.taxCode}}</div>
                    </div>
                    <div>{{$store.state.auth.user.address}}</div>
                </div>
            </div>
            <div class="row mb--md--8 font-weight--sm--500">
                <div class="col col--sm--12 col--md--4">
                    <div class="d--sm--flex align-items--sm--center mb--sm--2">
                        <PersonalIcon class="mr--sm--3"/>
                        <span>{{$store.state.auth.user.personRef}}</span>
                    </div>
                    <div class="d--sm--flex align-items--sm--center mb--sm--2">
                        <FoneIcon class="mr--sm--3"/>
                        <span>{{$store.state.auth.user.phone}}</span>
                    </div>
                </div>
                <div class="col col--sm--12 col--md--4">
                    <div class="d--sm--flex align-items--sm--center mb--sm--2">
                        <CakeIcon class="mr--sm--3"/>
                        <span>{{$store.state.auth.user.birthday}}</span>
                    </div>
                    <div class="d--sm--flex align-items--sm--center mb--sm--2">
                        <EnvelopIcon class="mr--sm--3"/>
                        <span>{{$store.state.auth.user.email}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="h5 d--sm--flex align-items--sm--center pl--sm--4 ml--md--0 pr--sm--4 mr--md--0 customer-action"
            v-on:click="$router.push('/order-history').catch(()=>{})">
            <HistoryIcon class="mr--sm--3"/>
            <span>Lịch sử đơn hàng</span>
        </div>
        <hr/>
        <div class="h5 d--sm--flex align-items--sm--center mb--sm--4 mb--md--6 pl--sm--4 ml--md--0 pr--sm--4 mr--md--0">
            <CallIcon class="mr--sm--3"/>
            <span>Liên hệ trình dược viên</span>
        </div>
        <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8">
            <span>{{$store.state.auth.user.tdvName}}</span>
        </div>
        <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8">
            <a class="d--sm--flex align-items--sm--center" :href="`tel:${$store.state.auth.user.tdvPhone}`" :title="$store.state.auth.user.tdvPhone">
                <PhoneIcon class="mr--sm--3"/>
                <span>{{$store.state.auth.user.tdvPhone}}</span>
            </a>
        </div>
        <div class="mb--sm--4 mb--md--6 d--sm--flex align-items--sm--center font-weight--sm--500 pl--sm--8">
            <a class="d--sm--flex align-items--sm--center" :href="`malto:${$store.state.auth.user.tdvEmail}`">
                <MailIcon class="mr--sm--3"/>
                <span>{{$store.state.auth.user.tdvEmail}}</span>
            </a>
        </div>
        <hr/>
        <div
            v-on:click="$router.push('/pharmacy-information').catch(()=>{})"
            class="h5 d--sm--flex align-items--sm--center pl--sm--4 ml--md--0 pr--sm--4 mr--md--0 customer-action">
            <QuestionIcon class="mr--sm--3"/>
            <span>Thông tin Boston Pharma</span>
        </div>
        <hr/>
        <div
            v-on:click="$router.push('/change-password-front').catch(()=>{})"
            class="h5 d--sm--flex align-items--sm--center pl--sm--4 ml--md--0 pr--sm--4 mr--md--0 customer-action">
            <LockIcon class="mr--sm--3"/>
            <span>Đổi mật khẩu</span>
        </div>
        <div class="mt--sm--9 mt--md--20">
            <div class="col col--sm--12 col--md--6 col--md--offset--3"><HdButton v-on:click="onLogoutClickHandle" fullWidth>Đăng xuất</HdButton></div>
        </div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'
import LockIcon from '@/assets/lock.svg'
import QuestionIcon from '@/assets/question.svg'
import MailIcon from '@/assets/mail-footer-icon.svg'
import PhoneIcon from '@/assets/phone-footer-icon.svg'
import CallIcon from '@/assets/call.svg'
import FoneIcon from '@/assets/fone.svg'
import PersonalIcon from '@/assets/personal.svg'
import CakeIcon from '@/assets/cake.svg'
import EnvelopIcon from '@/assets/envelop.svg'
import HistoryIcon from '@/assets/history-icon.svg'
import Avatar from '@/assets/avatar.svg'

// Import Components
import HdButton from '@/components/HdButton'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    HotlineMobileCard,
    LockIcon,
    QuestionIcon,
    MailIcon,
    PhoneIcon,
    CallIcon,
    FoneIcon,
    PersonalIcon,
    CakeIcon,
    EnvelopIcon,
    HistoryIcon,
    HdButton,
    Avatar
  },
  data () {
    return {}
  },
  methods: {
    onLogoutClickHandle: function (event) {
      this.$confirm('Bạn có muốn đăng xuất không?')
        .then(_ => {
          this.$store.dispatch('auth/logOut').then(response => {
            this.$notify({
              duration: 1000,
              title: 'Thành công!',
              message: this.$t('logout.success'),
              type: 'success'
            })
            this.$router.push('/login').catch(() => {})
          })
            .catch(_ => {})
        })
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: false,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', true)
  }
}
</script>

<style lang="scss" scoped>
    @import "@/scss/respoinsive";
    .personal-card {
        @include mobile() {
            background-color: #E5F6FF;
            padding: 24px;
        }
    }
    .personal-info {
        display: flex;
        align-items: center;
        &__avata {
            position: relative;
            width: 77px;
            height: 77px;
            border-radius: 50%;
            border: solid 1px #59C6BC;
            margin-right: 16px;
        }
        &__status-dot {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: solid 3px #ffffff;
            position: absolute;
            top: 0;
            right: 0;
            background-color: #FFC400;
        }
        &__content {
            flex: 1;
        }
    }
    .customer-action {
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            opacity: 0.75
        }
    }
</style>
